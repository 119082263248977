@import 'variables';

//Main
.main {
    text-align: center;  
    display: grid;
    grid-template-rows: 5vh 90vh 5vh;
    grid-template-columns: auto auto auto 1fr auto;
    background: #fbfbff;

    .menus{
        grid-column: 1/3;
        display:grid;
        grid-template-rows: auto;
        grid-template-columns: auto auto;
        position:fixed; 
    }
    

    .visualization{
        grid-column: 5;
        margin: 20px;
        grid-row: 2;
        place-self: center;
        max-width: 85vw;
        height:100%
    }

    .plot {
        background: #ffffff;
        border: 1px dashed $tab-grey;
        min-width:50vw;
        min-height: 60vh;
        max-width:85vw;
        max-height: 85vh;
        padding:20px;
    }
}
h4{
    margin-bottom: 0;
    margin-top: 0;
}

p{
    margin: 5px;
}

button {
    @extend %button;
}

#info-button {
    padding: 5px;
    color:$medium-pr-color;
    border-color: $medium-pr-color;
    position: absolute;
    right: 2vw;
    top: 1vw;
}

#info-button:hover {
    padding: 5px;
    color: white;
    background-color:$medium-pr-color;
    border-color: $medium-pr-color;
    position: absolute;
    right: 2vw;
    top: 1vw;
}

.primary {
    color:white;
    background-color: $medium-pr-color;
    @extend %button;
}

.primary:hover {
    background-color: $strong-pr-color;
}



select{
    // border:1px solid $inactive-grey;
    margin:5px;
    padding:5px;
    cursor:pointer;
    @extend %regular-text;
}

button {
    @extend %button;
}

.primary {
    color: #7275c6;
    background-color: transparent;
    font-weight: 600;
    @extend %button;
}

.primary:hover {
    color: #fff;
    background-color: $strong-pr-color;
}



textarea {
    width: 100%;
    margin-top: 1em;
    min-height: 16lh;
    resize: none;
    color: inherit;
    font-size: .8em;
}

a {
     color: $medium-pr-color
}

a:hover{
    color: $strong-pr-color
}

a:active{
    color: $dark-pr-color
}
  
  
  
  
 
  

  
  
  
  
