@import '../../variables';

.centerContainer{
    grid-column: 1/-1;
    justify-self: center;
    margin-top: 100px;
    grid-row: 1/-1;
}

table {
    height:auto;
    font-size: 12px;
    border: $table-border;
    margin-left: auto;
    margin-right: auto;
}

table, th, td {
    border: 1px solid $light-pr-color;
    border-collapse: collapse;
    padding:5px;
}

ul {
    list-style-type: none;
    margin-block-start: 0px;
}

li {
    text-align: left;
}

.dataInfo{
    display: grid;
    grid-template-columns: 30% 70%;
    background: $background-color-extra-light;
    padding: 20px;
    margin: 20px;
    border-radius: 2px;
    border: 1px solid $tab-grey;
    min-height:250px;
}

.menuTitle{
    grid-column:1/-1;
}

hr{
    grid-column:1/-1;
    width:100%;
    border: 0;
    height: 1px;
    background-color: $light-pr-color;
}

.metadata{
    grid-column:1;
}

.dataFields{
    grid-column:2;
    font-size: 12px;
    text-align: left;
    margin-left: 50px;
}

label {
    font-size:12px;
}

.label-bold {
    font-weight: 600;
}

#noData {
    place-self: center;
    grid-column: 1/-1;
    color: #cccedc;
    font-size: 20px;
}

.rowContainer {
    display: flex; /* Enable Flexbox */
    align-items: center; /* Align items vertically */
    gap: 10px; /* Optional: Adds some space between the <p> and <ul> */
  }
  
  .myList {
    list-style-type: none; /* Removes default list styling */
    padding: 0;
    margin: 0;
    display: flex; /* Display list items in a row */
    gap: 10px; /* Optional: Adds some space between list items */
  }
  
  .myList li {
    display: flex; 
    justify-content: center; /* Centers items horizontally */
    align-items: center; /* Centers items vertically */
  }

  .myList li label {
    display: flex;
    align-items: center; /* Ensure label content is aligned */
    cursor: pointer;
  }
  
  .myList li input[type="checkbox"] {
    margin-right: 5px; /* Add some space between the checkbox and the label */
  }


.errorMessageButton {
    font-size:12px;
    font-weight:600;
    color: $strong-pr-color;
    margin-top:2px;
}



// #omvField{
//     grid-column:2;
// }

// #columnField{
//     grid-column:3;
// }