@import '../../variables';

.tab {
    grid-column:2;
    height:100vh;
    display:grid;
    grid-template-columns: auto auto;
    grid-template-rows: 50px 1fr;
    background: white;
}

.tabMenu {
    width: 40px;
    grid-column:1;
    grid-row: 1/-1;

    .rotatedText {
        text-align:right;
        grid-row: 1/-1;
        white-space: nowrap;
        transform: rotate(270deg);
        margin-top: 10px;
        position:relative;
        top: 77px;
    }

    #expandIcon {
        margin-top: 10px;
        font-size: 20px;
    }

    
    #expandIcon:hover {
        cursor: pointer;
    }
}

#visualVariables {
    //Table
    table, th, td {
        border: 1px solid $light-pr-color;
        border-collapse: collapse;
    }
  
    table {
        grid-row: 1/-1;
        grid-column: 2;
        width: 100%;
        height: 100vh;
        border:0px;
        text-align: center;
        border-left: $table-border;
        border-bottom: $table-border;
        place-self: center;
        font-size: 10px;
        @extend %regular-text;

    td {
        height: $cell-height
      }
    
    th {
        padding: 0px;
        margin:0px;
        width:$cell-height;
        height:$cell-height;
    }

    .disable-cell{
        background: $inactive-grey;
    }

    .header-group {
        width: 60px;
        background: $background-color;
        // border-top: $table-border;
        // border-bottom: $table-border;
        font-size: 10px;
        font-weight: 600;
    }

    #visualChannel {
        background: $light-pr-color;
    }

    .categoryChannel{
        background: $light-pr-color;
        text-align: left;
        padding: 2px;
        border-bottom: $table-border;
        border-top: $table-border;  
        
        td {
            height:2vw;
            max-width: 4vw;
            padding-left: 5px;
            font-weight:600;
        }
    }
    
    .column-group-separator {
        border-right: $table-border; 
    }


    .var {
        background: $background-color;
        border-left: $table-border;
        font-weight: 500;
    }

    #empty-th {
        height:2vw; 
        border: none; 
        padding: 0;
    }
      
    .border-bot {
        border-bottom: $table-border;
    }

    img{
        display:block;
        margin:1px;
        max-height: 2vw;
        max-width: 2vw;
    }

    input[type="checkbox"]:checked:before{
        background-color: #2E3283;
        background: #2E3283;
        color:#7275C6;
    }

    input[type="checkbox"]:checked:after{
        background-color: #2E3283;
        background: #2E3283;
        color:#7275C6;
    }

    #plus{
        position: absolute;
        font-size: 18px;
        width: 40px;
        z-index: 1;
        color: $strong-pr-color;
    }

    #plus-inactive{
        position: absolute;
        font-size: 18px;
        width: 40px;
        z-index: 1;
        color: #bbbcc6;
    }

    #connection-line {
        position: absolute;
        width: 48px;
        background-color: $light-pr-color;
        height: 2px;
        margin-left: 32px;
        margin-top: -10px;
        z-index: 0;
    }
  }
}
    
    