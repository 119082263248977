@import '../../variables';

.menu {
    grid-column: 2; 
    background-color: $background-color-extra-light;
    height:100vh;
    display:grid;
    // grid-template-columns: auto auto;
    // grid-template-rows: 50px 1fr;

    .tabMenu {
        width: 40px;
        background-color:$tab-grey;
        border-right: 2px solid $line-color;

        .rotatedTextM {
            white-space: nowrap;
            transform: rotate(270deg);
            position:relative;
            top: 110px;
        }

        #expandIcon {
            margin-top: 10px;
            font-size: 20px;
        }

        #expandIcon:hover {
            cursor: pointer;
        }
    }

    .menuTitle {
        grid-row: 1;
        grid-column: 2;
        justify-self: center;
        align-self: center;
        margin-bottom: 5px;
        margin-top: 10px;
    }

    .titleLine{
        grid-row: 1;
        grid-column: 2;
        height: 1px;
        align-self: flex-end;
        background-color: $line-color;
    }


    .menuContainer {
        grid-column:2;
    }

   

    label {
        @extend %regular-text;
    }

    .line {
        height: 1px;
        background-color: $line-color;
        margin-bottom:10px;
        margin-top:2px;
        margin-right:2px;
    }

    .menuField {
        margin:10px;

    }

    .markSelection{
        // display:flex;
        // flex-direction:row;
        // justify-content: center;
    }

    .labelMark{
        display:flex;
        justify-content: center;
        align-items: center;
    }

    .markIcons{
        height:30px;
        width:30px;
        border: 1px solid $inactive-grey;
        margin:5px;
    }

    #selectedIcon{
        height:30px;
        width:30px;
        // border: 3px solid $medium-pr-color; 
    }
}