// STYLE VARIABLES
//--------------------------
//Colors
//text
$text-color:#2E3283;
//purples
$dark-pr-color:#2E3283;
$strong-pr-color: #7275C6;
$medium-pr-color: #9498F6;
$light-pr-color: rgb(210 211 243);


//greys
$inactive-grey: #ebecf4;
$tab-grey: #d6d8e7;
$line-color: #bbbdff;

//background
$background-color:#edeef9;
$background-color-extra-light:#f3f3f9;

//Table
$cell-height: 20px;
$table-border: 1.5px solid $dark-pr-color;

//BASIC COMPONENTS
//-------------------------
%regular-text {
    color: $text-color;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
}

%button {
    margin:6px;
    padding:6px;
    cursor:pointer;
    border-radius: 2px;
    border:2px solid $strong-pr-color;
    @extend %regular-text;
}
