@import '../../variables';

.dataMenu {
    grid-column: 1; 
    background-color: $background-color-extra-light;
    height:100vh;
    width:250px;
    display:grid;
    // grid-template-columns: auto auto;
    // grid-template-rows: 50px 1fr;

    .tabMenu {
        width: 40px;
        background-color:$tab-grey;
        border-right: 2px solid $line-color;

        .rotatedTextData {
            white-space: nowrap;
            transform: rotate(270deg);
            margin-top: 10px;
            text-align: right;
            text-align:right;
            position:relative;
            top: 14px;
        }

        #expandIcon {
            margin-top: 10px;
            font-size: 20px;
        }

        
        #expandIcon:hover {
            cursor: pointer;
        }
    }

    .menuTitle {
        grid-row: 1;
        grid-column: 2;
        font-size: 14px;
        justify-self: center;
        align-self: center;
        margin-bottom: 5px;
        margin-top: 10px;
    }

    .titleLine{
        grid-row: 1;
        grid-column: 2;
        height: 1px;
        align-self: flex-end;
        background-color: $line-color;
    }


    .menuContainer {
        grid-column:2;
        text-align: center;
    }

   

    label {
        @extend %regular-text;
    }

    .line {
        height: 1px;
        background-color: $line-color;
        margin-bottom:10px;
        margin-top:2px;
        margin-right:2px;
    }

    .menuField {
        margin:10px;

    }

    .labelMark{
        display:flex;
        justify-content: left;
        align-items: center;
        margin-left: 25%;
    }

    .markIcons{
        height:30px;
        width:30px;
        border: 1px solid $inactive-grey;
        margin:5px;
    }

    #selectedIcon{
        height:30px;
        width:30px;
        // border: 3px solid $medium-pr-color; 
    }

    table {
        height:auto;
        font-size: 12px;
        border: $table-border;
        margin-left: auto;
        margin-right: auto;
    }

    table, th, td {
        border: 1px solid $light-pr-color;
        border-collapse: collapse;
        padding:5px;

        td{
            max-width: 60px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    ul {
        list-style-type: none;
        margin-block-start: 0px;
    }

    li {
        text-align: left;
    }

 

      .dataFields{
        text-align: center;
        padding: 5px;
        margin-left:5px;
      }
    
      .labelLarge{
        font-size: 14px;
        font-weight: 600;
      }

      .file-upload {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      
      input[type="file"] {
        display: none; /* Hide the file input */
      }
      
      .custom-file-upload {
        color: #7275c6;
        background-color: transparent;
        font-weight: 600;
        @extend %button;

      }

      .smaller-text{
        margin-top:10px;
        font-size: 12px;
      }
      
    //   .custom-file-upload:hover {
    //     background-color: #0056b3;
    //   }
}