.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    width: 40vw;
    max-width: 80%;
  }

  .modal-header{
    text-align: right;
    height:20px;
  }

  
  .modal-info{
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }

  #close-modal{
    position:relative;
    border: none;
    background-color: transparent;
    font-size: 20px;
    color: grey;
  }